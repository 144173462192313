import { api } from 'common/Common';
import React, { useState, useEffect } from 'react';
import {
    Button, Modal, ModalHeader, ModalBody,
    Tooltip, Toast, ToastHeader, ToastBody
} from 'reactstrap';

const ViewTasks = (props) => {

    const {
        customer_id,
        customer_name
    } = props;
    
    const [customer_tasks, setCustomerTasks] = useState([]);
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
    const [tooltipOpen, setTooltipOpen] = useState(false);

    useEffect( () => {
        fetchCustomerTasks();
    }, []);

    const fetchCustomerTasks = async () => {

        try {
            const res = await api.get(`v1/shop/customer/tasks/${customer_id}`);
            if(res) {
                setCustomerTasks(res.data.customer_tasks);
            }
        } catch (error) {
            console.log(error.response.data.errors);
        }
    }

    return (
        <>
            <Button id={`view-${customer_id}`}  color="info" onClick={toggle} className="action-btn"> View Tasks <i className="fa fa-eye"></i> </Button>
            <Tooltip placement="top" isOpen={tooltipOpen} target={`view-${customer_id}`} toggle={toggleTooltip}>
                View Task
            </Tooltip>
            <Modal isOpen={modal} toggle={toggle} className="black-text" size="lg" backdrop={true}>
                <ModalHeader className="justify-content-center" toggle={toggle}>
                    { customer_name } Tasks:
                </ModalHeader>
                <ModalBody>
                    {
                        customer_tasks.map( (task, index) => {
                            return (
                                <div className="p-3 my-2 rounded">
                                    <Toast key={index}>
                                        <ToastHeader icon={ `${ (task.status == "open") ? "success" : "danger"} ` }>
                                            { task.tasks.name }
                                        </ToastHeader>
                                        <ToastBody>
                                            <strong>Status: </strong> { task.status } <br></br>
                                            <strong>Reward Amount: </strong> $ { task.tasks.reward_amount }
                                        </ToastBody>
                                    </Toast>
                                </div>
                            )
                        })
                    }
                </ModalBody>
            </Modal>
        </>
    );
}

export default ViewTasks;