import React, { useState } from 'react';
import {
    Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import '../../../assets/css/task.css';

const HelpTask = () => {

    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);


    return (
        <>
            {/* <Button color="danger" onClick={toggle} className={buttonClass} ><i className="fa fa-plus"></i> {buttonLabel} </Button> */}
            <p onClick={toggle} className="text-center clickable-content">Need help on creating tasks?</p>
            <Modal isOpen={modal} toggle={toggle} size="md" backdrop={false}>
                <ModalHeader className="justify-content-center" toggle={toggle}>
                    Help Page
                </ModalHeader>
                <ModalBody>
                <div className="black-text">
                    <ol className="p-0">
                        <li key="1">
                            Click on Create New Task
                        </li>
                        <li key="2">
                            Choose on specific Task you want to create
                        </li>
                        <li key="3">
                            Fill up the form to create new specific tasks
                            <ul>
                                <li key="3.1">Enter Task Name</li>
                                    <dd key="3.1.1">-Name of the task that will be shown to the list in the table tasks and customer's</dd>
                                <li key="3.2">Task Description</li>
                                    <dd key="3.2.1">
                                        -Description of the task
                                    <br />
                                    eg. Write a google review for 'shop name' to earn rewards.
                                    </dd>
                                <li key="3.3">Reward Title</li>
                                    <dd key="3.3.1">
                                        -Title of the reward
                                    <br />
                                    eg. Christmas Rewards
                                    </dd>
                                <li key="3.4">Reward Amount</li>
                                    <dd key="3.4.1">-Amount of the reward to be rewarded to the customer</dd>
                                <li key="3.5">Task link</li>
                                    <dd key="3.5.1">-Prodive a link that the customer will be redirected into</dd>
                                <li key="3.6">Task Time Duration</li>
                                    <dd key="3.6.1">-Set the minimum time(in seconds) on how long the customer must do the task before giving the reward</dd>
                                <li key="3.7">Select Products</li>
                                    <dd key="3.7.1">-Select the specific product that you want customers to buy in order to gain reward</dd>
                                <li key="3.8">Desired Amount</li>
                                    <dd key="3.8.1">-Set the specific minimum amount that you want customer to buy in total to gain reward</dd>
                            </ul>
                        </li>
                        <li key="4">
                            Click on Submit
                        </li>
                        <li key="5">
                            After seeing successful message, your task has been added to the table.
                        </li>
                    </ol>
                </div>
                </ModalBody>
            </Modal>
        </>
    );
}

export default HelpTask;