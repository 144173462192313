/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.js";
import Tasks from "views/Tasks/Tasks";
import Customers from "views/Customers/Customers";

const routes = [
  { path: "/dashboard", exact: true, name: "Dashboard", icon: "tim-icons icon-chart-pie-36", component: Dashboard, layout: "/admin" },

  // tasks
  { path: "/tasks", exact: true, name: "Tasks", icon: "tim-icons icon-bullet-list-67", component: Tasks, layout: "/admin" },
  { path: "/customers", exact: true, name: "Customers", icon: "tim-icons icon-badge", component: Customers, layout: "/admin" },

];
export default routes;
