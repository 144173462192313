/**
 * @global localStorage
 * @global JSON
 * */
import React from 'react';
import axios from 'axios';
import createHistory from 'history/createBrowserHistory';
const history = createHistory();

/**
 * Get the cached appState in local storage
 * 
 * @returns {
 *  isLoggedIn: boolean,
 *  shop: { auth_token: null, domain: null, email: null, money_format: null, name: null, owner: null }
 * }
 */
const appState = () => {

    let cacheState = null

    let initialAppState = {
      isLoggedIn: false,
      shop: {
        id: null,
        auth_token: null,
        domain: null,
        email: null,
        money_format: null,
        name: null,
        owner: null
      }
    }
  
    try {
      cacheState = JSON.parse(localStorage.getItem('appState'));
    } catch(err) {}
  
    return cacheState ? cacheState : initialAppState;

  }

  /**
 * Instantiate Axios with the API base URL and auth token
 * as a parameter
 *
 * ```
 * @Usage:
 *  import { api } from 'common/Common';
 *  api.get('/uri', {...})
 *  api.post('/uri', {...})
 * ```
 * @returns {AxiosInstance}
 */
const baseURL = process.env.REACT_APP_API_URL || 'http://localhost:8000/api/';

const api = axios.create({
  baseURL: baseURL//'https:' === document.location.protocol ? baseURL.replace(/http:/, 'https:') : baseURL
});

api.interceptors.request.use(function(config) {

  const appData = appState();
  if ( appData && appData.shop.auth_token ) {

    config.headers.Authorization = appData.shop.auth_token;
    // config.headers.content_type = 'application/liquid';
    //header('Content-Type:application/liquid');
  }

  return config;

}, function(err) {
  return Promise.reject(err);
});

api.interceptors.response.use(response => response,function (error) {
  if (error.response.status === 401) {
    localStorage.clear();
    // history.push('/auth');
    window.location.replace('/403');
  }
  return Promise.reject(error);
});

export {
    api,
    appState,
    history
}