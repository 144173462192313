/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch, Redirect } from "react-router-dom";

import AdminLayout from "layouts/Admin/Admin.js";
import Auth from "layouts/Auth/Auth";
import AuthRedirect from "layouts/Auth/AuthRedirect";
import Extension from "layouts/Extension/Extension";
import Page404 from "views/Page404";
import Page403 from "views/Page403";

import "assets/scss/black-dashboard-react.scss";
import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
const history = createBrowserHistory();
/**
 * AlertProvider options
*/
// optional configuration
const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_CENTER,
  timeout: 5000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE,
  containerStyle: {
    zIndex: 1051
  }
}

ReactDOM.render(
  <AlertProvider template={AlertTemplate} {...options}>
    <Router history={history}>
      <Switch>
        <Route exact path="/" component={Page403} />
        <Route path="/admin" render={props => <AdminLayout {...props} />} />
        <Route path="/auth" component={Auth}/>
        <Route path="/redirect" component={AuthRedirect}/>
        <Route path="/apps/my-app-proxy" component={Extension} />
        <Route component={Page404} />
        <Route exact path="/403" component={Page403} />
      </Switch>
    </Router>
  </AlertProvider>,
  document.getElementById("root")
);
