import React, { Fragment } from 'react';
import {
  Container, Row, Col,
  Button, Form, FormGroup, Label, Input, FormText,
  Card, CardHeader, CardBody, CardTitle, CardText
} from 'reactstrap';
import { api } from '../../common/Common';
import "../../assets/css/app-extension.css";
import axios from 'axios';

class Extension extends React.Component {

  constructor(props) {
    super(props);
    document.body.classList.add("white-content");
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleWindowOpen = this.handleWindowOpen.bind(this);
    this.state = {
      tasks: []
    };

    var window;
  }

  componentDidMount() {
    console.log('mounting component...');
    this.getTasks();
  }

  async getTasks() {

    try {

      const api = axios.create({
        baseURL: `${process.env.REACT_APP_API_URL}`,
        headers: {
          'Content-Type': 'application/liquid',
        }
      });

      const res = await api.get(`${process.env.REACT_APP_API_URL}/v1/shop/proxy/task`);

      if(res) {
        console.log(res);
        this.setState({
          tasks: res.data.tasks
        });
      }

      // console.log('customer id: ', );
    } catch (error) {
      console.log('Error: ', error);
    }
  }

  componentDidUpdate(e) {

  }

  addGoogleReviewForm = () => {
    return (
      <Form>
        <FormGroup>
          <Label for="exampleText">Create new google review:</Label>
          <Input type="textarea" name="text" id="exampleText" style={{ color: "#FFFFFF !important" }} />
        </FormGroup>
        <Button onClick={this.handleSubmit}>Submit</Button>
      </Form>
    );
  }

  handleWindowOpen = (task) => {

      var news = window.open("", "NewsWindow", "width=900, height=700");
      var tmot = setTimeout(function () {
        news.window.open(`${task.link}`, '_self')
      }, 1000);

      tmot = setTimeout(function () {
        news.close();
        console.log('window close fires...');
      }, (task.duration * 1000));

    }

    handleSubmit = async (e) => {
      e.preventDefault();
      console.log('form clicked...');

    // this.setState({ isOpen: true });

    // const clean = sanitizeHtml(`<p><iframe src="${task}"></iframe><p>`, {
    //   allowedTags: ['p', 'em', 'strong', 'iframe'],
    //   allowedClasses: {
    //     'p': ['fancy', 'simple'],
    //   },
    //   allowedAttributes: {
    //     'iframe': ['src']
    //   },
    //   allowedIframeHostnames: ['www.youtube.com', 'player.vimeo.com']
    // });

    // return clean;
  }





  render() {
    
    const customer_id = "{{ customer.id }}";

    const template = '<p style="color: tomato;">Hello {{ name }} {{ customer.email }} {{ id }}</p>'
    const data = {
      name: 'aquibm',
      id: "{{ customer.id }}"
    }
    
    return (
      <div className="reward-container">
        <meta httpEquiv="content-type" content-type="application/liquid" />
        <Container>
          <Row>
            {
              console.log('customer id: ', customer_id)
            }
            <Col className="d-flex justify-content-center">
              <div className="mt-3">
                <p className="mb-0 main-title">Reward Kings</p>
                <p className="mb-0 main-description">Do the task and get rewards.</p>
              </div>
            </Col>
          </Row>
          <Row className="mt-5 d-flex justify-content-center">

            {
              this.state.tasks.map((task, index) => (
                <>
                  <Card className="col-md-8 shadow-lg p-3 mb-5 rounded task-card" key={index}>
                    <CardHeader><h3>{task.name}</h3></CardHeader>
                    <CardBody>
                      <CardTitle><h4>Instruction / Description: </h4></CardTitle>
                      <CardText> {task.description} </CardText>
                      <Button onClick={() => this.handleWindowOpen(task)} target="_blank" color="success" className="pull-right"> Proceed to link  <i className="tim-icons icon-double-right" /></Button>
                    </CardBody>
                  </Card>
                </>
              ))
            }
          </Row>
        </Container>
      </div>
    );
  }
}

export default Extension;
