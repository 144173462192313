/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
import React, { useState, useEffect } from 'react';
import {
    Row, Col, Button, Modal, ModalHeader, ModalBody,
    Card, CardHeader, CardBody, CardTitle, CardText,
    Tooltip
} from 'reactstrap';
import { useAlert } from "react-alert";
import '../../../assets/css/task.css';
import { api } from '../../../common/Common';

const ViewTaskModal = (props) => {

    const {
        modalClass,
        buttonClass,
        task,
    } = props;


    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);
    const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const id = task.id;
    const [name, setName] = useState(task.name);
    const [description, setDescription] = useState(task.description);
    const [rewardTitle, setRewardTitle] = useState(task.reward_title);
    const [rewardAmount, setRewardAmount] = useState(task.reward_amount);
    const [taskLink, setTaskLink] = useState(task.link);
    const [taskDuration, setTaskDuration] = useState(task.duration);
    const [certainProduct, setCertainProduct] = useState(task.purchase_product);
    const [storeProducts, setStoreProducts] = useState([]);
    const [certainAmount, setCertainAmount] = useState(task.purchase_amount);
    const [taskType, setTaskType] = useState(task.type);

    useEffect(() => {
        setName(task.name)
        setDescription(task.description)
        setRewardTitle(task.reward_title)
        setRewardAmount(task.reward_amount)
        setTaskLink(task.link)
        // setTaskDuration(task.duration)
        setCertainProduct(task.purchase_product)
        // setStoreProducts()
        setCertainAmount(task.purchase_amount)
        setTaskType(task.type)
    }, [modal])


    return (
        <>
            <Button id={`view-${task.id}`} color="info" onClick={toggle} className={buttonClass}><i className="fa fa-eye"></i> </Button>
            <Tooltip placement="top" isOpen={tooltipOpen} target={`view-${task.id}`} toggle={toggleTooltip}>
                View Task
            </Tooltip>
            <Modal isOpen={modal} toggle={toggle} className={modalClass} size="lg" backdrop={true}>
                <ModalHeader className="justify-content-center" toggle={toggle}>
                    View Task
                </ModalHeader>
                <ModalBody>
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h2" className="text-center">{name}</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col>
                                    <p>{description}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>Reward Title: {rewardTitle}</p>
                                    <p>Reward Amount: {rewardAmount}</p>
                                </Col>
                                <Col>
                                    {
                                        taskType === 'google-review' ? (
                                            <p>Link: {taskLink}</p>
                                        ) : taskType === 'certain-product' ? (
                                            certainProduct ? (
                                                <p>Buy this product: {certainProduct.product_title}</p>
                                            ) : (
                                                    <p>Buy this product: </p>
                                                )
                                        ) : taskType === 'certain-amount' ? (
                                            <p>Buy a total of {certainAmount}</p>
                                        ) : (
                                                        <div>
                                                        </div>
                                                    )
                                    }
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </ModalBody>
            </Modal>
        </>
    );
}

export default ViewTaskModal;