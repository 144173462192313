import React from "react";
import axios from 'axios';
import "../../assets/css/auth.css";

class Auth extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    console.log('mounting component...');
    this.install();
  }

  async install() {

    const query = new URLSearchParams(this.props.location.search);

    const params = {
        'hmac': query.get('hmac'),
        'shop': query.get('shop'),
        'timestamp': query.get('timestamp')
    }
    
    console.log(params);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/v1/install`, params);
        if(res) {
          console.log('', res.data);
          window.location.replace(res.data.redirect_url);
        }
    } catch(error) {
        console.log('Error: ', error);
    }

  }

  componentDidUpdate(e) {
    
  }

  render() {

    return (
      <>
        <div className="content">
            <div className="loader">Loading...</div>
        </div>
      </>
    );
  }
}

export default Auth;
