import React, { useState, useEffect } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import '../../../assets/css/task.css';
import { api } from '../../../common/Common';

const SelectProduct = (props) => {

    const {
        handleProductChange,
        selectedProduct
    } = props;

    const [certainProduct, setCertainProduct] = useState({});
    const [products, setProducts] = useState([]);

    const handleChange = (e) => {
        const selectedProduct = products[e.target.value];
        setCertainProduct(e.target.value);
        handleProductChange(selectedProduct);
    };

    useEffect(() => {
        fetchProducts();
    }, []);


    useEffect(() => {
        // check if selectedProduct and products have value
        if (selectedProduct && products) {
            // find the index for selected product in the products array 
            const value = products.findIndex(e => e.variant_product_id === selectedProduct.variant_product_id);
            // set the index as initial value of select products input
            setCertainProduct(value);
        }

    }, [products]);


    const fetchProducts = async () => {

        try {

            const res = await api.get('v1/shop/products');

            if (res) {

                let newSplitProduct = [];
                res.data.products.forEach(product => {
                    product.variants.forEach(variant => {
                        newSplitProduct.push({
                            product_id: product.id,
                            product_title: product.title + " - " + variant.price,
                            variant_id: variant.id,
                            variant_price: variant.price,
                            variant_product_id: variant.product_id
                        });
                    });
                });

                setProducts(newSplitProduct);

            }

        } catch (error) {
            console.log(error.response.data.errors);
        }

    }

    return (
        <>
            <div className="content">
                <FormGroup>
                    <Label for="productOption">Select Products</Label>
                    <Input type="select" onChange={handleChange} value={certainProduct}>
                        <option value="" > - Select Product - </option>
                        {
                            products.map((product, index) => (
                                <option
                                    key={index}
                                    value={index}
                                > { product.product_title} </option>
                            ))
                        }
                    </Input>
                </FormGroup>
            </div>
        </>
    );
}

export default SelectProduct;