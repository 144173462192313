import React, { useState } from "react";
import { // reactstrap components
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  CardFooter
} from "reactstrap";
import SelectTask from './SelectTask';
import AddTaskModal from './components/AddTaskModal';
import EditTaskModal from './components/EditTaskModal';
import ViewTaskModal from './components/ViewTaskModal';
import DeleteTaskModal from './components/DeleteTaskModal';
import HelpTask from "./components/HelpTaskModal";
import BootstrapTable from 'react-bootstrap-table-next';
import '../../assets/css/task.css';
import { api } from '../../common/Common';

class Tasks extends React.Component {

  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      tasks: [],
      storeProducts: [],
      productFetching: false,
      tableDataIndication: ''
    }

  }

  async componentWillMount() {
    this.getTasks();
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /**
   * Get task from API
   */
  async getTasks() {
    let indication;
    indication = "Getting Tasks";
    this.setState({
      tableDataIndication: indication
    });
    this._isMounted = true;
    try {
      const res = await api.get('v1/shop/task');
      if (res && this._isMounted) {
        indication = res.data.tasks.length > 0 ? indication = "" : indication = "No Tasks Yet";
        this.setState({
          tasks: res.data.tasks,
          tableDataIndication: indication
        });
      }
    } catch (error) {
      indication = "Can't Get Tasks";
      this.setState({
        tableDataIndication: indication
      });
    }

  }

  /* 
    fetch() {
    this._isMounted = true;
    // set current state
    this.setState({ user: appState(), searching: true, domainChoose: false });

    api
    .get(`domain`)
    .then( json => {
      if (this._isMounted) {
        let preferredSubDomains = JSON.parse(json.data.data.preferredSubDomains);
        this.setState({
          preferredDomainData : preferredSubDomains.SuggestionsList,
          searching: false,
          domainChoose: true,
          domainSelected: json.data.data.isReserve[0].domains
        });
      }
    });
  }

  */

  /**
   * Add new task
   */
  addTask = async (task) => {
    //update state here . . .
    this.setState({ tasks: [...this.state.tasks, task] });
  }

  /**
   * Update task row
   */
  updateTask = async (task) => {

    // update table row state data
    const rows = this.state.tasks;
    const index = rows.findIndex((x) => x.id === task.id);

    if (index > -1) {
      rows[index].name = task.name;
      rows[index].description = task.description;
      this.setState({ tasks: rows })
    }

  }

  /**
   * Delete task row
   */
  deleteRow = async (task) => {
    this.getTasks();
  }

  render() {
    const tasks = this.state.tasks;

    const columns = [{
      dataField: 'name',
      text: 'Task Name'
    }, {
      dataField: 'description',
      text: 'Description'
    }, {
      dataField: 'reward_title',
      text: 'Reward'
    }, {
      dataField: 'reward_amount',
      text: 'Amount',
      formatter: (rowContent, row) => {
        return ("$ " + row.reward_amount)
      }
    }, {
      dataField: 'action',
      text: 'Action',
      classes: 'text-center align-middle',
      headerStyle: (colum, colIndex) => {
        return { width: '15%', textAlign: 'center' };
      },
      formatter: (rowContent, row) => {
        return (
          [
            <>
              <EditTaskModal key={`edit-${row.id}`} task={row} buttonClass={"action-btn"} modalClass={"black-text"} updateTask={this.updateTask} /* storeProducts={this.state.storeProducts} */ />
              <DeleteTaskModal key={`delete-${row.id}`} task={row} buttonClass={"action-btn"} modalClass={"black-text"} deleteRow={this.deleteRow} />
              <ViewTaskModal key={`view-${row.id}`} buttonClass={"action-btn"} modalClass={"black-text"} task={row} />
            </>
          ]
        );

      }
    }];

    return (
      <>
        <div className="content">
          {/* <div class="loader">Loading...</div> */}
          <Row>
            <Col md="12">
              <div className="clearfix"></div>
              <Card>
                <CardHeader>
                  <CardTitle tag="h2" className="text-center">Tasks List: </CardTitle>
                  <SelectTask buttonLabel={"Create New Task"} buttonClass="float-right" addTask={this.addTask} /* storeProducts={this.state.storeProducts} */ />
                </CardHeader>
                <CardBody>
                  <BootstrapTable keyField='id' data={tasks} columns={columns} noDataIndication={this.state.tableDataIndication} />
                </CardBody>
                <CardFooter>
                  <HelpTask />
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Tasks;
