/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
import React, { useState, useEffect } from 'react';
import { 
  Button,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Form, FormGroup, Label, FormText, Tooltip,
  Input, InputGroup, InputGroupAddon, InputGroupText
} from 'reactstrap';
import { useAlert } from "react-alert";
import '../../../assets/css/task.css';
import { api } from '../../../common/Common';
import SelectProduct from './SelectProduct';

const EditTaskModal = (props) => {

  const {
    modalClass,
    buttonClass,
    task,
    updateTask,
  } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const id = task.id;
  const [name, setName] = useState(task.name);
  const [description, setDescription] = useState(task.description);
  const [rewardTitle, setRewardTitle] = useState(task.reward_title);
  const [rewardAmount, setRewardAmount] = useState(task.reward_amount);
  const [taskLink, setTaskLink] = useState(task.link);
  const [taskDuration, setTaskDuration] = useState(task.duration);
  const [certainProduct, setCertainProduct] = useState(task.purchase_product);
  const [storeProducts, setStoreProducts] = useState([]);
  const [certainAmount, setCertainAmount] = useState(task.purchase_amount);
  const [taskType, setTaskType] = useState(task.type);

  const alert = useAlert();

  useEffect(() => {
    // Update the document title using the browser API
    // document.title = `You clicked ${count} times`;

  }, []);

  const handleUpdate = async (e) => {

    e.preventDefault();

    try {
      
      const task = {
        id: id,
        name: name,
        description: description,
        reward_title: rewardTitle,
        reward_amount: rewardAmount,
        link: taskLink,
        duration: taskDuration,
        purchase_product: JSON.stringify(certainProduct),
        purchase_amount: certainAmount,
        type: taskType
      };
  
      const res = await api.put(`v1/shop/task/${id}`, task);
  
      if(res) {
        updateTask(task);
        setModal(!modal);
        alert.success(res.data.message);
      }

    } catch(error) {
      alert.error(error.response.data.message, {});
    }
    
  }

  const onTaskNameChange = (e) => {
    const name = e.target.value;
    setName(name)
  };
  const onTaskDescriptioneChange = (e) => {
    const description = e.target.value;
    setDescription(description)
  };
  const onRewardTitleChange = (e) => {
    const rewardTitle = e.target.value;
    setRewardTitle(rewardTitle)
  };
  const onRewardAmountChange = (e) => {
    const rewardAmount = e.target.value;
    if (!rewardAmount || rewardAmount.match(/^\d{1,}(\.\d{0,2})?$/)) {
      setRewardAmount(rewardAmount)
    }
  };
  const onTaskLinkChange = (e) => {
    const taskLink = e.target.value;
    setTaskLink(taskLink)
  };
  const onTaskDurationChange = (e) => {
    const taskDuration = e.target.value;
    setTaskDuration(taskDuration)
  };
  const onCertainAmountChange = (e) => {
    const certainAmount = e.target.value;
    if (!certainAmount || certainAmount.match(/^\d{1,}(\.\d{0,2})?$/)) {
      setCertainAmount(certainAmount)
    }
  };

  const handleProductChange = (product) => {
    setCertainProduct(product);
  }

  return (
    <>
      <Button id={`edit-${id}`} color="success" onClick={toggle} className={buttonClass}><i className="fa fa-edit"></i> </Button>
      <Tooltip placement="top" isOpen={tooltipOpen} target={`edit-${id}`} toggle={toggleTooltip}>
        Edit Task
      </Tooltip>
      <Modal isOpen={modal} toggle={toggle} className={modalClass}>
        <ModalHeader toggle={toggle}>Edit Task</ModalHeader>
        <ModalBody>

        <Form onSubmit={ handleUpdate }>
        <FormGroup>
              <Label for="taskDescription">Task Name: </Label>
              <Input type="text" value={name} onChange={onTaskNameChange} placeholder="Enter task name..." required />
            </FormGroup>
            <FormGroup>
              <Label for="taskDescription">Task Description: </Label>
              <Input type="textarea" value={description} onChange={onTaskDescriptioneChange} required />
            </FormGroup>
            <FormGroup>
              <Label for="rewardTitle">Reward Title: </Label>
              <Input type="text" value={rewardTitle} onChange={onRewardTitleChange} />
            </FormGroup>
            <FormGroup>
              <Label for="rewardAmount">Reward Amount: </Label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>$</InputGroupText>
                </InputGroupAddon>
                <Input type="number" value={rewardAmount} onChange={onRewardAmountChange} placeholder="Enter amount here..." style={{ height: "41px" }} required />
              </InputGroup>
            </FormGroup>

            {
              task.type === 'google-review' ? (
                <div>
                  {/* Google Review Task */}
                  <FormGroup>
                    <Label for="taskLink"> Task Link : </Label>
                    <Input type="text" value={taskLink} onChange={onTaskLinkChange} placeholder="Enter link here..." />
                  </FormGroup>
                  <FormGroup>
                    <Label for="taskDuration">Task Time Duration : </Label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>Seconds: </InputGroupText>
                      </InputGroupAddon>
                      <Input type="number" value={taskDuration} onChange={onTaskDurationChange} placeholder="Set how long the task should go..." style={{ height: "41px" }} required />
                    </InputGroup>
                  </FormGroup>
                </div>
              ) : task.type === 'certain-product' ? (
                <div>
                  {/* Load select product dropdown component */}
                  <SelectProduct handleProductChange={handleProductChange} selectedProduct={certainProduct}/>
                </div>
              ) : task.type === 'certain-amount' ? (
                <div>
                  {/* Certain Amount Task */}
                  <FormGroup>
                    <Label for="rewardAmount">Desired Amount: </Label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>$</InputGroupText>
                      </InputGroupAddon>
                      <Input type="number" value={certainAmount} onChange={onCertainAmountChange} placeholder="Enter amount here..." style={{ height: "41px" }} required />
                    </InputGroup>
                  </FormGroup>
                </div>
              ) : (
                    <div>
                    </div>
                  )

            }

          <Button className="float-right" color="success">Update</Button>
        </Form>

        </ModalBody>
        <ModalFooter>
          {/* <Button color="primary" onClick={toggle}>Do Something</Button>{' '} */}
          {/* <Button color="secondary" className="float-right" onClick={toggle}>Close</Button> */}
        </ModalFooter>
      </Modal>
    </>
  );
}

export default EditTaskModal;