import React, { useState, useEffect } from 'react';
import { // reactstrap components
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    Button,
    CardFooter
} from "reactstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import '../../assets/css/task.css';
import { api } from '../../common/Common';
import ViewTasks from './components/ViewTasks';

const Customers = (props) => {

    const appState = JSON.parse(localStorage.getItem('appState'));

    // Declare a new state variable, which we'll call "count"
    const [customers, setCustomers] = useState([]);

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        fetchCustomers();
    }, []);

    const fetchCustomers = async () => {
        try {
            const res = await api.get(`v1/shop/customers/${appState.shop.id}`);
            if(res) {
                console.log('res', res);
                setCustomers(res.data.customers);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const columns = [
    {
        dataField: 'name',
        text: 'Name',
        formatter: (rowContent, row) => {
            return (row.first_name+" "+row.last_name);
        }
    },
    {
        dataField: 'email',
        text: 'Email'
    },
    {
        dataField: 'task_subscribed',
        text: 'Task Subscribed',
        formatter: (rowContent, row) => {
            return (
                <ViewTasks customer_id={row.id} customer_name={ `${row.first_name} ${row.last_name}` }/>
            )
        }
    },
    {
        dataField: 'rewards_earn',
        text: 'Rewards Earned',
        headerStyle: (colum, colIndex) => {
            return { width: '15%', textAlign: 'center' };
        },
        formatter: (rowContent, row) => {
            return ("$ " + row.rewards)
        }
    }
    /* {
        dataField: 'discount_codes',
        text: 'Discount Codes Generated',
        headerStyle: (colum, colIndex) => {
            return { width: '10%', textAlign: 'left' };
        },
        formatter: (rowContent, row) => {
            return ("View");
        }
    } */];

    return (
        <>
            <div className="content">
                <Row>
                    <Col md="12">
                        <div className="clearfix"></div>
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h2" className="text-center">Subscribed Customers: </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <BootstrapTable keyField='id' data={customers} columns={columns} noDataIndication="No customers subscribed yet!" />
                            </CardBody>
                            <CardFooter>
                                {/* <HelpTask /> */}
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );

}

export default Customers;