/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
import React, { useState, useEffect } from 'react';
import { Button, Tooltip } from 'reactstrap';
import { useAlert } from "react-alert";
import '../../../assets/css/task.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { api } from '../../../common/Common';

const DeleteTaskModal = (props) => {

  const { task, deleteRow } = props;  
  
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const alert = useAlert();

  useEffect(() => {
    // Update the document title using the browser API
    // document.title = `You clicked ${count} times`;
  });

  const handleDelete = async () => {
    confirmAlert({
      title: 'Confirm to submit',
      message: `Are you sure you want to delete this task named "${task.name}"`,
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            const res = await api.delete(`v1/shop/task/${task.id}`);
            if(res) {
              deleteRow(task.id);
              alert.success(res.data.message);
            }
          }
        },
        {
          label: 'No',
          onClick: () => { console.log('dialog closes...') }
        }
      ]
    });

  }

  return (
    <>
      <Button
        id={`delete-${task.id}`} 
        onClick={ handleDelete }
        className="btn btn-danger action-btn"
      >
        <i className="fa fa-trash"></i>
      </Button>
      <Tooltip placement="top" isOpen={tooltipOpen} target={`delete-${task.id}`} toggle={toggleTooltip}>
        Delete Task
      </Tooltip>
    </>
  );
}

export default DeleteTaskModal;