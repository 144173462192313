import React from "react";
import { Row, Col } from 'reactstrap';

class Page403 extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <div className="container-fluid" style={{ height: "100%" }}>
            <Row style={{ width: "100%", margin: 0, position: "absolute", top: "50%", "-ms-transform": "translateY(-50%)", transform: "translateY(-50%)"}}>
                <Col md="4" style={{ textAlign: "right" }}>
                    <h1>Error 403: </h1>
                </Col>
                <Col md="6">
                    <h2>AUTHENTICATION FAILED / SHOPIFY STORE NOT VERIFIED</h2>
                </Col>
            </Row>
        </div>
      </>
    );
  }
}

export default Page403;
