import React, { useState, useEffect } from 'react';
import {
    Button,
    Card, CardBody, CardTitle, CardText,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Row, Col
} from 'reactstrap';
import '../../assets/css/task.css';
import { api, appState } from '../../common/Common';
import AddTaskModal from './components/AddTaskModal';

const SelectTask = (props) => {

    const {
        buttonLabel,
        modalClass,
        buttonClass,
        addTask,
    } = props;

    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    // update state data of task component
    const updateStateData = async (data) => {
        addTask(data);
    }

    return (
        <>
            <Button color="danger" onClick={toggle} className={buttonClass} ><i className="fa fa-plus"></i> {buttonLabel} </Button>

            <Modal isOpen={modal} toggle={toggle} className={modalClass} size="lg" backdrop={false}>
                <ModalHeader className="justify-content-center" toggle={toggle}>
                    Create New Task
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <CardTitle><h3>Purchase A Certain Product</h3></CardTitle>
                                    <CardText>
                                        Create a task that will make your customer purchase your an specific product and set reward amount for that task.
                                    </CardText>
                                    <AddTaskModal buttonLabel={"Create New Task"} buttonClass="float-right" updateStateData={updateStateData} taskType={'certain-product'} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <CardTitle><h3>Reached Certain Amount</h3></CardTitle>
                                    <CardText>
                                        Create a task that will send reward to the customer if they reach the certain amount you set.
                                    </CardText>
                                    <AddTaskModal buttonLabel={"Create New Task"} buttonClass="float-right" updateStateData={updateStateData} taskType={'certain-amount'} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <CardTitle><h3>Task With Link Redirection</h3></CardTitle>
                                    <CardText>
                                        Make customers redirect to the link provided.
                                        <br />
                                        <i>eg. Fb page link, Youtube channel, Google review </i>
                                    </CardText>
                                    <AddTaskModal buttonLabel={"Create New Task"} buttonClass="float-right" taskType={'google-review'} updateStateData={updateStateData} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </>
    );
}

export default SelectTask;