/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Form, FormGroup, Label, FormText,
  Input, InputGroup, InputGroupAddon, InputGroupText
} from 'reactstrap';
import { useAlert } from "react-alert";
import '../../../assets/css/task.css';
import { api, appState } from '../../../common/Common';
import SelectProduct from './SelectProduct';

const AddTaskModal = (props) => {
  const {
    modalClass,
    buttonClass,
    taskType,
    updateStateData
  } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const [taskName, setTaskName] = useState('');
  const [taskDescription, setTaskDescription] = useState('');
  const [rewardTitle, setRewardTitle] = useState('');
  const [rewardAmount, setRewardAmount] = useState('');
  const [taskLink, setTaskLink] = useState('');
  const [taskDuration, setTaskDuration] = useState('');
  const [certainProduct, setCertainProduct] = useState('');
  const [certainAmount, setCertainAmount] = useState('');

  const alert = useAlert();

  const handleSubmit = async (e) => {

    e.preventDefault();

    try {

      const res = await api.post('v1/shop/task', {
        shop_id: appState().shop.id,
        name: taskName,
        type: taskType,
        description: taskDescription,
        reward_title: rewardTitle,
        reward_amount: rewardAmount,
        duration: taskDuration,
        link: taskLink,
        purchase_product: JSON.stringify(certainProduct),
        purchase_amount: certainAmount
      });

      if (res) {
        // send back added task to the parent component
        updateStateData(res.data.task); 
        setModal(!modal);
        alert.success(res.data.message);
      }

    } catch (error) {

      alert.error(error.response.data.message, {});

      /* Display multiple error messages */
      /*
        const error_response = error.response.data.errors;
            var error_messages = [];
      
            for (var key in error_response) {
              if (error_response.hasOwnProperty(key)) {
                console.log(key + " -> " + error_response[key]);
                error_messages.push(error_response[key]);
              }
            }
      
            alert(error_messages);
      */
    }

  }

  const onTaskNameChange = (e) => {
    const taskName = e.target.value;
    setTaskName(taskName)
  };
  const onTaskDescriptioneChange = (e) => {
    const taskDescription = e.target.value;
    setTaskDescription(taskDescription)
  };
  const onRewardTitleChange = (e) => {
    const rewardTitle = e.target.value;
    setRewardTitle(rewardTitle)
  };
  const onRewardAmountChange = (e) => {
    const rewardAmount = e.target.value;
    if (!rewardAmount || rewardAmount.match(/^\d{1,}(\.\d{0,2})?$/)) {
      setRewardAmount(rewardAmount)
    }
  };
  const onTaskLinkChange = (e) => {
    const taskLink = e.target.value;
    setTaskLink(taskLink)
  };
  const onTaskDurationChange = (e) => {
    const taskDuration = e.target.value;
    setTaskDuration(taskDuration)
  };
  const onCertainAmountChange = (e) => {
    const certainAmount = e.target.value;
    if (!certainAmount || certainAmount.match(/^\d{1,}(\.\d{0,2})?$/)) {
      setCertainAmount(certainAmount)
    }
  };

  const handleProductChange = (product) => {
    setCertainProduct(product);
  }

  return (
    <>
      <Button color="primary" onClick={toggle} className={buttonClass}>Select</Button>
      <Modal isOpen={modal} toggle={toggle} className={modalClass} backdrop={false}>
        <ModalHeader toggle={toggle}>Create New Task</ModalHeader>
        <ModalBody>

          <Form onSubmit={handleSubmit} >
            <FormGroup>
              <Label for="taskDescription">Task Name: </Label>
              <Input type="text" value={taskName} onChange={onTaskNameChange} placeholder="Enter task name..." required />
            </FormGroup>
            <FormGroup>
              <Label for="taskDescription">Task Description: </Label>
              <Input type="textarea" value={taskDescription} onChange={onTaskDescriptioneChange} required />
            </FormGroup>
            <FormGroup>
              <Label for="rewardTitle">Reward Title: </Label>
              <Input type="text" value={rewardTitle} onChange={onRewardTitleChange} />
            </FormGroup>
            <FormGroup>
              <Label for="rewardAmount">Reward Amount: </Label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>$</InputGroupText>
                </InputGroupAddon>
                <Input type="number" value={rewardAmount} onChange={onRewardAmountChange} placeholder="Enter amount here..." style={{ height: "41px" }} required />
              </InputGroup>
            </FormGroup>

            {
              taskType === 'google-review' ? (
                <div>
                  {/* Google Review Task */}
                  <FormGroup>
                    <Label for="taskLink"> Task Link : </Label>
                    <Input type="text" value={taskLink} onChange={onTaskLinkChange} placeholder="Enter link here..." />
                  </FormGroup>
                  <FormGroup>
                    <Label for="taskDuration">Task Time Duration : </Label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>Seconds: </InputGroupText>
                      </InputGroupAddon>
                      <Input type="number" value={taskDuration} onChange={onTaskDurationChange} placeholder="Set how long the task should go..." style={{ height: "41px" }} required />
                    </InputGroup>
                  </FormGroup>
                </div>
              ) : taskType === 'certain-product' ? (
                <div>
                  {/* Load select product dropdown component */}
                  <SelectProduct handleProductChange={handleProductChange} />
                </div>
              ) : taskType === 'certain-amount' ? (
                <div>
                  {/* Certain Amount Task */}
                  <FormGroup>
                    <Label for="rewardAmount">Desired Amount: </Label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>$</InputGroupText>
                      </InputGroupAddon>
                      <Input type="number" value={certainAmount} onChange={onCertainAmountChange} placeholder="Enter total amount to be purchased..." style={{ height: "41px" }} required />
                    </InputGroup>
                  </FormGroup>
                </div>
              ) : (
                <div>

                </div>
              )

            }

            <Button className="float-right" color="success">Submit</Button>
          </Form>

        </ModalBody>
        <ModalFooter>
          {/* <Button color="primary" onClick={toggle}>Do Something</Button>{' '} */}
          {/* <Button color="secondary" className="float-right" onClick={toggle}>Close</Button> */}
        </ModalFooter>
      </Modal>
    </>
  );
}

export default AddTaskModal;