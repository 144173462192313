import React from "react";
import axios from 'axios';
import "../../assets/css/auth.css";

class AuthRedirect extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    console.log('mounting component...');
    this.authenticate();
  }

  componentDidUpdate(e) {
    
  }

  async authenticate() {

    const query = new URLSearchParams(this.props.location.search);

    const params = {
      'code': query.get('code'),
      'hmac': query.get('hmac'),
      'shop': query.get('shop'),
      'timestamp': query.get('timestamp')
    }
    
    console.log(params);

    try {

      const res = await axios.post(`${process.env.REACT_APP_API_URL}/v1/authenticate`, params);

      if(res) {

        console.log(res);
        const { shop_info, access_token } = res.data;

        let shopData = {
          id: shop_info.id,
          auth_token: access_token,
          name: shop_info.name,
          email: shop_info.email,
          domain: shop_info.domain,
          owner: shop_info.shop_owner,
          money_format: shop_info.money_format,
        }

        let appState = {
          isLoggedIn: true,
          shop: shopData
        }

        // save app state with user date in local storage
        // localStorage["appState"] = JSON.stringify(appState);
        localStorage.setItem('appState', JSON.stringify(appState));
        window.location.replace('/admin/dashboard');

      }
    } catch(error) {
      console.log('Error: ', error);
    }

  }

  render() {
    return (
      <>
        <div className="content">
            {/* <div className="loader">Redirecting...</div> */}
        </div>
      </>
    );
  }
}

export default AuthRedirect;
